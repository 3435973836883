import { VehicleImageUrls,FinanceQuote } from "static/js/app/models/__index";
import { VehicleFile } from "static/js/app/models/vehicleFile";

export class Vehicle {
    constructor(
        public type: string,
        public vehicle_type: string,
        public vehicle_type_display: string,
        public fuel_type: string,
        public fuel_type_display: string,
        public manufacturer: string,
        public manufacturer_display: string,
        public model: string,
        public model_display: string,
        public derivative: string,
        public derivative_display: string,
        public body_type: string,
        public body_name: string,
        public gearbox: string,
        public transmission: string,
        public transmission_display: string,
        public description: string,
        public engine_capacity: number,
        public mpg: number,
        public year_built: number,
        public mileage: number | undefined,
        public price: number,
        public list_price: number,
        public discount_price: number,
        public engine: string,
        public co2: number,
        public tax_rate_12: number | null,
        public monthly_payment: number,
        public branch_name: string,
        public insurance_group: string,
        public id: string,
        public images: VehicleImageUrls[],
        public imageCount: number,
        public colour: string,
        public basic_colour: string,
        public basic_colour_display: string,
        public urban_mpg: number,
        public extra_urban_mpg: number,
        public height: number,
        public width: number,
        public length: number,
        public acceleration: number,
        public bhp: number,
        public max_speed: number,
        public features: string,
        public vehicle_sash: string,
        public notes: string,
        public datasheetPath: string,
        public sold: boolean,
        public branch_id: number,
        public vat_exempt: boolean,
        public vat_excluded: boolean,
        public vat_qualifying: boolean,
        public unladened_weight: number,
        public mtplm: number,
        public internal_length: number,
        public awning_size: number,
        public seats: number,
        public doors: number,
        public mot_expiry: Date,
        public body_length: number,
        public internal_width: number,
        public exhaust_manufacturer: string,
        public muffler: string,
        public wheelbase: number,
        public boot_space_seats_down: number,
        public boot_space_seats_up: number,
        public kerb_weight: number,
        public max_loading_weight: number,
        public berth: number,
        public category: string,
        public layout: string,
        public youtube_link: string,
        public manufacturers_warranty: boolean,
        public manufacturers_warranty_expiry: Date,
        public equipment_drivers_convenience: string,
        public equipment_safety: string,
        public equipment_exterior: string,
        public equipment_interior: string,
        public equipment_technical: string,
        public equipment_security: string,
        public equipment_additional: string,
        public source_advert_id: string,
        public cap_id: string,
        public ivendi_quotee_uid: string,
        public vrm: string,
        public registration_date: Date,
        public finance_quotes: FinanceQuote[],
        public file_links: VehicleFile[],
        public equipment_entertainment: string,
        public equipment_wheels: string,
        public equipment_packs: string,
        public equipment_trim: string,
        public websection: string,
        public websection_display: string,
        public reg_letter: string,
        public reserved: boolean,
        public new_vehicle: boolean,
        public used_or_new: string,
        public interior_colour: string,
        public ulez_compliant: boolean | null,
        public battery_capacity_kwh: number| null, 
        public battery_range_miles: number| null,
        public service_history: string| null,
        public left_hand_drive: boolean | null,
        public has_price_from: boolean | null 
        

       

    ) {
        this.body_name = Vehicle.tweakBodyTypeDisplay(body_name);
    }

    private static tweakBodyTypeDisplay(bodyTypeDisplay: string) {
        return (bodyTypeDisplay == null) ? null : bodyTypeDisplay.replace("MpvEstate", "MPV and Estate");
    }

    public get main_image(): VehicleImageUrls | null {
        return this.imageCount > 0 ? this.images[0] : null;
    }

    public get seoTitle(): string {
        return `${this.year_built} ${this.manufacturer_display} ${this.model_display} ${this.derivative_display || ""}`;
    }

    public get urlPath(): string {
        const vehicleTypePlural = (this.vehicle_type && this.vehicle_type.length) ? this.vehicle_type.toLowerCase() + 's' : '';
        const vehicleBasePath = (this.new_vehicle ==true ? "/new-vehicles/": "/used/")
        const derivativePart = (this.derivative != null && this.derivative.length > 0) ? `${this.derivative}-` : "";
        return `${vehicleBasePath}${vehicleTypePlural}/${this.manufacturer}-${this.model}-${derivativePart}${this.id}/`;
    }

    public get discount_amount(): number {
        return this.list_price - this.price;
    }
}
